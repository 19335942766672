<template>
  <div>
    <base-nav
      :class="{'navbar-light': type === 'default'}"
      container-classes="container-fluid"
      class="bg-white navbar-top border-bottom navbar-expand"
    >
      <!-- Search form -->
      <div class="header_logo position-relative">
        <span class="h2_text h2 align-middle d-none d-lg-block mb-0">{{ client ? (client.company_abbreviation ? client.company_abbreviation : client.company_name) : ' ' }}&nbsp;</span>
      </div>

      <!-- Navbar links -->
      <div class="menu-right position-absolute">
        <ul class="navbar-nav align-items-center">
          <li class="nav-item d-xl-none float-left float-md-none">
            <!-- Sidenav toggler -->
            <div
              :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
              class="sidenav-toggler"
              @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line" />
                <i class="sidenav-toggler-line" />
                <i class="sidenav-toggler-line" />
              </div>
            </div>
          </li>

          <base-dropdown
            class="nav-item"
            tag="li"
            title-classes="text-success nav-link"
            title-tag="a"
            menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
            :title="notificationCount > 0 ? (notificationCount + '') : ''"
            title-span-class="notification"
          >
            <template v-if="notificationCount > 0" slot="title">
              <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.45768 7C5.40759 7 2.12435 10.2832 2.12435 14.3333V20.3333H0.791016V21.6667H18.1243V20.3333H16.791V14.3333C16.791 10.2832 13.5078 7 9.45768 7Z" fill="#2DCE89" />
                <path d="M6.12435 23.6667V23H12.791V23.6667C12.791 25.5076 11.2986 27 9.45768 27C7.61673 27 6.12435 25.5076 6.12435 23.6667Z" fill="#2DCE89" />
                <circle cx="16.1606" cy="7.63257" r="6.70312" fill="#DA2727" />
              </svg>
              <span v-if="notificationCount > 1" class="vpo-notifi-count">{{ notificationCount }}</span>
            </template>
            <template v-else slot="title">
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4577 0C6.40759 0 3.12435 3.28325 3.12435 7.33333V13.3333H1.79102V14.6667H19.1243V13.3333H17.791V7.33333C17.791 3.28325 14.5078 0 10.4577 0Z" fill="#2DCE89" />
                <path d="M7.12435 16.6667V16H13.791V16.6667C13.791 18.5076 12.2986 20 10.4577 20C8.61673 20 7.12435 18.5076 7.12435 16.6667Z" fill="#2DCE89" />
              </svg>
            </template>

            <template>
              <Notifications @newNotificationCount="updateNotificationCount($event)" />
            </template>
          </base-dropdown>
        </ul>
        <ul class="navbar-nav d-inline-block">
          <li class="nav-item">
            <a
              class="nav-link"
              @click="redirectPage"
            >
              <font-awesome-icon icon="fas fa-house" style="color: #4ccb89; height: 20px; width: 20px; cursor: pointer" />
            </a>
          </li>
        </ul>
        <ul class="navbar-nav d-inline-block">
          <li class="nav-item">
            <SwitchLocale @lang="getLang"/>
          </li>
        </ul>
        <ul class="navbar-nav d-inline-block">
          <base-dropdown
            menu-on-right
            class="nav-item"
            tag="li"
            title-tag="a"
            title-classes="nav-link pr-0"
          >
            <a slot="title-container" href="#" class="nav-link pr-0" @click.prevent>
              <div class="media align-items-center">

                <div class="media-body d-none d-lg-block">
                  <span class="mb-0 text-sm font-weight-500 mr-2">{{ user.name }}</span>
                </div>
                <vue-avatar class="vue-avatar" :username="user.name" :size="31" :rounded="true" :src="avatar" />
              </div>
            </a>

            <template>
              <a
                v-for="ss in sessions"
                :key="ss.id"
                class="dropdown-item"
                href="#"
                @click.prevent="switch_account(ss)"
              >
                <div class="d-flex flex-column" :style="!ss.is_current ? 'margin-left: 16px' : ''">
                  <span><i v-if="ss.is_current" class="fas fa-chevron-right mr-2" />{{ ss.name }}</span>
                  <span :style="ss.is_current ? 'margin-left: 16px' : ''">{{ ss.company_name }}</span>
                </div>
              </a>
              <hr class="m-0">
              <a class="dropdown-item" href="#" @click.prevent="add_account">
                <font-awesome-icon icon="far fa-plus-large" />
                <span v-text="$t('add_account')">{{ $t('add_account') }}</span>
              </a>
              <hr class="m-0">
              <a class="dropdown-item" @click="$router.push({name: 'ho_so_ca_nhan.thong-tin-tai-khoan'}).catch(()=>{})">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.0498 0.510742H2.2998C1.80252 0.510742 1.32561 0.708286 0.97398 1.05992C0.622349 1.41155 0.424805 1.88846 0.424805 2.38574V11.1357C0.424805 11.633 0.622349 12.1099 0.97398 12.4616C1.32561 12.8132 1.80252 13.0107 2.2998 13.0107H11.0498C11.5471 13.0107 12.024 12.8132 12.3756 12.4616C12.7273 12.1099 12.9248 11.633 12.9248 11.1357V2.38574C12.9248 1.88846 12.7273 1.41155 12.3756 1.05992C12.024 0.708286 11.5471 0.510742 11.0498 0.510742ZM11.6748 11.1357C11.6748 11.3015 11.609 11.4605 11.4917 11.5777C11.3745 11.6949 11.2156 11.7607 11.0498 11.7607H2.2998C2.13404 11.7607 1.97507 11.6949 1.85786 11.5777C1.74065 11.4605 1.6748 11.3015 1.6748 11.1357V2.38574C1.6748 2.21998 1.74065 2.06101 1.85786 1.9438C1.97507 1.82659 2.13404 1.76074 2.2998 1.76074H11.0498C11.2156 1.76074 11.3745 1.82659 11.4917 1.9438C11.609 2.06101 11.6748 2.21998 11.6748 2.38574V11.1357ZM6.6748 4.26074C6.34328 4.26074 6.02534 4.39244 5.79092 4.62686C5.5565 4.86128 5.4248 5.17922 5.4248 5.51074C5.42612 5.72888 5.4845 5.94288 5.59415 6.13147C5.70379 6.32006 5.86088 6.47667 6.0498 6.58574V8.63574C6.0498 8.8015 6.11565 8.96047 6.23286 9.07768C6.35007 9.19489 6.50904 9.26074 6.6748 9.26074C6.84057 9.26074 6.99954 9.19489 7.11675 9.07768C7.23396 8.96047 7.2998 8.8015 7.2998 8.63574V6.58574C7.48873 6.47667 7.64582 6.32006 7.75546 6.13147C7.86511 5.94288 7.92349 5.72888 7.9248 5.51074C7.9248 5.17922 7.79311 4.86128 7.55869 4.62686C7.32427 4.39244 7.00633 4.26074 6.6748 4.26074Z"
                    fill="#2DCE89"
                  />
                </svg>
                <span>{{ $t('model.employees.account_info') }}</span>
              </a>
              <a class="dropdown-item" href="#" @click.prevent="logout">
                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.81692 1.25027C1.22806 0.83913 1.78568 0.608154 2.36712 0.608154H9.29025C9.87168 0.608154 10.4293 0.839128 10.8404 1.25027C11.2516 1.66141 11.4826 2.21903 11.4826 2.80047V4.24279C11.4826 4.657 11.1468 4.99279 10.7326 4.99279C10.3184 4.99279 9.98256 4.657 9.98256 4.24279V2.80047C9.98256 2.61686 9.90962 2.44076 9.77979 2.31093C9.64996 2.18109 9.47386 2.10815 9.29025 2.10815H2.36712C2.18351 2.10815 2.00741 2.18109 1.87758 2.31093C1.74774 2.44076 1.6748 2.61686 1.6748 2.80047V11.4544C1.6748 11.638 1.74774 11.8141 1.87758 11.9439C2.00741 12.0738 2.18351 12.1467 2.36712 12.1467H9.29025C9.47386 12.1467 9.64996 12.0738 9.77979 11.9439C9.90962 11.8141 9.98256 11.638 9.98256 11.4544V10.0121C9.98256 9.59785 10.3184 9.26206 10.7326 9.26206C11.1468 9.26206 11.4826 9.59785 11.4826 10.0121V11.4544C11.4826 12.0358 11.2516 12.5934 10.8404 13.0046C10.4293 13.4157 9.87168 13.6467 9.29025 13.6467H2.36712C1.78568 13.6467 1.22806 13.4157 0.81692 13.0046C0.40578 12.5934 0.174805 12.0358 0.174805 11.4544V2.80047C0.174805 2.21903 0.40578 1.66141 0.81692 1.25027ZM12.5098 3.71268C12.8027 3.41978 13.2776 3.41978 13.5705 3.71268L16.4551 6.59731C16.748 6.89021 16.748 7.36508 16.4551 7.65797L13.5705 10.5426C13.2776 10.8355 12.8027 10.8355 12.5098 10.5426C12.2169 10.2497 12.2169 9.77484 12.5098 9.48195L14.1142 7.87761L6.08109 7.87747C5.66687 7.87746 5.33109 7.54167 5.3311 7.12746C5.33111 6.71324 5.6669 6.37746 6.08111 6.37747L14.1141 6.37761L12.5098 4.77334C12.2169 4.48045 12.2169 4.00557 12.5098 3.71268Z"
                    fill="#2DCE89"
                  />
                </svg>
                <span v-text="$t('logout')">{{ $t('logout') }}</span>
              </a>
            </template>
          </base-dropdown>
        </ul>
      </div>
    </base-nav>

    <modal
      :show.sync="remindChangeRandomPasswordModal"
      modal-classes="modal-dialog-centered"
      size="lg"
      class="vpo-modal"
      :showClose="false"
    >
      <template slot="header">
        <h2 class="text-white">
          {{ $t('change_password') }}
        </h2>
      </template>
      <div>
            <div class="position-relative c-shadow">
              <input
                v-model="newPassword"
                class="mb-3 form-control"
                name="confirm_password"
                :type="passwordFieldType"
                :placeholder="$t('login.enter_password')"
              />
              <button
                type="password"
                class="position-absolute btn btn-link"
                style="top: 0; right: 0"
                @click="switchVisibility('password')"
              >
                <i class="fas fa-eye" />
              </button>
            </div>
            <div class="position-relative c-shadow">
              <input
                v-model="confirmNewPassword"
                class="mb-3 form-control"
                name="confirm_password"
                :type="confirmFieldType"
                :placeholder="$t('login.confirm_password')"
              />
              <button
                type="password"
                class="position-absolute btn btn-link"
                style="top: 0; right: 0"
                @click="switchVisibility('confirm')"
              >
                <i class="fas fa-eye" />
              </button>
            </div>

            <div class="mt-3 text-center">
              <base-button
                type="primary"
                :loading="changingPassword"
                :disabled="changingPassword"
                class="font-weight-normal"
                @click.prevent="onChangeRandomPassword"
              >
                {{ $t('model.buttons.save') }}
              </base-button>
            </div>
      </div>
    </modal>

    <modal
      :show.sync="logoutModal"
      modal-classes="modal-dialog-centered"
      size="lg"
      class="vpo-modal"
    >
      <div class="d-flex flex-column">
        <h3 class="text-center mt-3">
          {{ $t('you_are_logging_out') }}
        </h3>
        <div class="modal-header pt-1">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <img src="/img/icons/close_ico.svg">
          </button>
        </div>
        <div class="text-center">
          <base-button
            type="primary"
            class="my-4 font-weight-normal"
            @click="logoutCurrentAccount"
          >
            {{ $t('logout_current_account') }}
          </base-button>
          <base-button
            type="primary"
            class="my-4 font-weight-normal"
            @click="logoutAllAccount"
          >
            {{ $t('logout_all_account') }}
          </base-button>
        </div>
      </div>
    </modal>
    <CheckConnection/>
  </div>
</template>
<script>
import { BaseNav, Modal } from '@/components'
import Helper from '@/util/helper'
import VueAvatar from 'vue-avatar'
import Notifications from '@frontendCommon/components/notifications'
import Swal from 'sweetalert2'
import SwitchLocale from '@frontendCommon/components/SwitchLocale'
import { mapState } from 'vuex'
import CheckConnection from '@frontendCommon/components/CheckConnection'

export default {
  name: 'DashboardNavbar',
  components: {
    BaseNav,
    Modal,
    VueAvatar,
    Notifications,
    SwitchLocale,
    CheckConnection,
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)',
    },
    id: {
      type: String,
      default: () => (''),
    },
    sex: {
      type: String,
      default: () => ('male'),
    },
  },
  data() {
    const user = this.$store.getters['auth/user']
    return {
      name: user.name,
      activeNotifications: false,
      notificationCount: 0,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      employee: {},
      remindChangeRandomPasswordModal: user.changed_random_password === 0,
      newPassword: '',
      confirmNewPassword: '',
      changingPassword: false,
      passwordFieldType: 'password',
      confirmFieldType: 'password',
      sessions: [],
      sessionId: `${user.id}-${user.client_id}`,
      logoutModal: false,
      toggled: '',
      language: '',
    }
  },
  mounted() {
    this.load_multiple_account()
    // Detect switch account
    window.sessionStorage.removeItem('sessionId')
    this.detectSwitchAccount()
    setInterval(() => {
      this.detectSwitchAccount()
    }, 10000)
    // Detect switch account
  },
  computed: {
    avatar() {
      const user = this.$store.getters['auth/user']
      return user ? user.avatar : ''
    },
    routeName() {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    },
    ...mapState({
      client: (state) => state.auth.client,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    getLang(value){
      this.language = value ? value : 'en'
    },
    logout() {
      if (this.sessions && this.sessions.length === 1) {
        return this.logoutCurrentAccount()
      }
      this.logoutModal = true
    },
    closeModal() {
      this.logoutModal = false
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    viewAll() {
      this.$router.push({ name: 'thong-bao-chi-tiet' })
    },
    showChangePassword() {
      this.remindChangeRandomPasswordModal = true
      this.newPassword = ''
      this.confirmNewPassword = ''
    },
    onChangeRandomPassword() {
      const checkNullCondition = this.newPassword !== '' && this.confirmNewPassword !== ''
      const checkMatchConfirmPassword =  this.newPassword === this.confirmNewPassword

      if(checkNullCondition){
        if(checkMatchConfirmPassword){
          this.changingPassword = true
          Helper.gqlMutate('User', 'changeRandomPassword', {
            password: this.newPassword,
          }).then((res) => {
            Swal.fire({
              title: this.$t('model.notifications.change_password_successfully'),
              text: '',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              this.changingPassword = false
              this.remindChangeRandomPasswordModal = false
            })
          }, (err) => {
            err.graphQLErrors.map(({ message }) => {
              let errorHTML = "<p>" + message + "</p>";
              Swal.close();
              Swal.fire({
                title: this.$t('model.notifications.change_password_failed'),
                icon: "error",
                html: errorHTML,
                confirmButtonText: 'OK',
              }).then(() => {
                this.changingPassword = false;
              });
            });
          })
        }
        else{
          Swal.fire({
            title: this.$t('model.notifications.change_password_mustmatch'),
            text: '',
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            this.changingPassword = false
          })
        }
      } else {
        Swal.fire({
          title: this.$t('model.notifications.change_password_failed'),
          text: '',
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(() => {
          this.changingPassword = false
        })
        return
      }
    },
    switchVisibility(type) {
      if(type === 'password'){
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      }
      if(type === 'confirm'){
        this.confirmFieldType = this.confirmFieldType === 'password' ? 'text' : 'password'
      }
    },
    load_multiple_account() {
      if (!window.localStorage.getItem('sessions')) { return }
      this.sessions = JSON.parse(window.localStorage.getItem('sessions'))
      // Change company name lang when locale lang change
      this.sessions.forEach((ss) => {
        const lang = this.$store.getters['lang/locale']
        if (ss.company_name_translations[lang]) {
          ss.company_name = ss.company_name_translations[lang]
        }
      })
    },
    add_account() {
      window.sessionStorage.setItem('add_user', true)
      this.$router.push({ name: 'dang-nhap' })
    },
    switch_account(ss) {
      if (ss.id === this.sessionId) { return }
      this.sessions = this.sessions.map((ss) => {
        ss.is_current = false
        return ss
      })
      window.localStorage.setItem('sessions', JSON.stringify(this.sessions))
      this.$store.dispatch('auth/switchSession', {
        sessionId: ss.id,
      })
    },
    logoutCurrentAccount() {
      const list = this.sessions.filter((ss) => !ss.is_current)
      if (list.length === 0) {
        this.logoutAllAccount()
      } else {
        this.sessions = [...list]
        this.sessions[0].is_current = true
        window.localStorage.setItem('sessions', JSON.stringify(this.sessions))
        this.switch_account(list[0])
      }
    },
    logoutAllAccount() {
      window.localStorage.setItem('sessions', '')
      this.$store.dispatch('auth/logout').then(() => {
        // this.$router.push({ name: 'dang-nhap' })
        window.location.href = '/dang-nhap';
      })
    },
    updateNotificationCount(count) {
      this.notificationCount = count
    },
    detectSwitchAccount() {
      const currentUrl = this.$route;
      const sessionId = window.sessionStorage.getItem('sessionId')
      const currentSessionId = this.getCurrentSessionId()
      if (!sessionId) {
        window.sessionStorage.setItem('sessionId', currentSessionId)
      } else if (sessionId !== currentSessionId && currentUrl.name != 'dang-nhap') {
        window.localStorage.setItem('sessionId', currentSessionId)
        // window.location.reload();
        Swal.fire({
          title: 'Bạn đã logout trên tab khác',
          text: '',
          icon: 'warning',
          confirmButtonText: 'Tải lại trang',
        }).then(() => {
          // window.location.href = 'about:blank'
          window.location.reload();
        })
      }
    },
    getCurrentSessionId() {
      let sessions = window.localStorage.getItem('sessions')
      let currentSession = ''
      if (sessions) {
        sessions = JSON.parse(sessions)
        sessions.forEach((ss) => {
          if (ss.is_current) {
            currentSession = ss.id
          }
        })
      }

      return currentSession
    },
    redirectPage() {
      // check current route != dashboard must direct
      if (this.$route.name !== 'dashboard') {
        this.$router.push({ name: 'dashboard' })
      }
    },
    handleToggleQuestion(){
      this.toggleQuestion = !this.toggleQuestion
    }
  },

}
</script>
<style lang="scss">
  .question-label{
    font-size: 16px;
    color: #2DCE89;
    cursor: pointer;
  }
  .question-text{
    color: #707070 !important;
  }

  .vpo-navbar-top {
    height: 52px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.16);
  }

  .vpo-header-right {
    margin-left: auto;

    .btn-rotate {
      background: transparent !important;
      border: 0;
      color: #fff;

      &:focus {
        border: 0;
        outline: 0;
      }
    }

    hr {
      margin: 0 !important;
    }
  }

  .vue-avatar {
    box-sizing: content-box;
    border: 3px solid #2DCE89;
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0;
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }
  }

  .avatar.rounded-circle {
    width: 40px;
    height: 40px;
  }

  .avatar.rounded-circle span{
    font-size: 10px;
  }

  .navbar-expand {
    .navbar-collapse {
      overflow: auto;

      .header_logo {
        width: 50%;
        z-index: 0;

        .h2_text {
          font-size: 25px;
          color: #707070;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
        }
      }

      .menu-right {
        width: 50%;
        right: 0;
        /* top: 5px; */
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }
  }
  .c-shadow {
    .form-control {
      border: 1px solid #dee2e6;
    }
  }
  .vpo-notifi-count{
    position: absolute;
    top: 5px;
    right: -1px;
    color: #fff;
    font-size: 9px;
    display: block;
    text-align: center;
    width: 16px;
    height: 16px;
  }
</style>

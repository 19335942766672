import { path } from 'ramda'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const page = (path) => () =>
  import(`~/pages/${path}`).then((m) => m.default || m)

const routes = [
  {
    path: '/',
    name: 'home',
    component: page('index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  { path: '/bao-tri', name: 'bao-tri', component: page('maintain-screen.vue') },

  {
    path: '/dang-nhap',
    name: 'dang-nhap',
    component: page('dang-nhap.vue'),
    permissions: [],
    advanced_permissions: [],
    noAuth: true,
  },
  {
    path: '/authentication/:device_id',
    name: 'authentication',
    component: page('authentication/index.vue'),
    permissions: [],
    advanced_permissions: [],
    noAuth: true,
  },
  {
    path: '/authentication-type-otp',
    name: 'authentication-type-otp',
    component: page('authentication/type-otp.vue'),
    permissions: [],
    advanced_permissions: [],
    noAuth: true,
  },
  {
    path: '/quen-mat-khau',
    name: 'quen-mat-khau',
    component: page('quen-mat-khau/index.vue'),
    permissions: [],
    advanced_permissions: [],
    noAuth: true,
  },
  {
    path: '/doi-mat-khau',
    name: 'doi-mat-khau',
    component: page('quen-mat-khau/confirm.vue'),
    permissions: [],
    noAuth: true,
  },

  // Ca nhan
  {
    path: '/time-sheet',
    name: 'timesheet',
    component: page('time-sheet/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/dieu-chinh-gio-lam-thuc-te',
    name: 'dieu-chinh-gio-lam-thuc-te',
    component: page('dieu-chinh-gio-lam-thuc-te/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/dang-ky-cong-so-nghi-phep',
    name: 'dang_ky_cong_so_nghi_phep',
    component: page('dang-ky-cong-so-nghi-phep/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/dang-ky-cong-so-ot',
    name: 'dang_ky_cong_so_ot',
    component: page('dang-ky-cong-so-ot/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/dang-ky-cong-so-cong-tac',
    name: 'dang_ky_cong_so_cong_tac',
    component: page('dang-ky-cong-so-cong-tac/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },

  {
    path: '/bang-luong-nhan-vien',
    name: 'bang_luong_nhan_vien',
    component: page('bang-luong-nhan-vien/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/bang-luong-nhan-vien/:id/chi-tiet',
    name: 'bang_luong_nhan_vien.chi_tiet',
    component: page('bang-luong-nhan-vien/chi-tiet.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/bang-luong-nhan-vien/:id/in',
    name: 'bang_luong_nhan_vien.in',
    component: page('bang-luong-nhan-vien/print.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/bang-luong/:id/export-payslip',
    name: 'bang_luong.export_payslip',
    component: page('bang-luong/export-payslip.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/bang-luong/:id/export-payslip/print/',
    name: 'bang_luong.export_payslip.print',
    component: page('bang-luong/export-payslip-print.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/khieu-nai-bang-luong',
    name: 'khieu_nai_bang_luong',
    component: page('khieu-nai-bang-luong/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/khieu-nai-bang-luong/:id',
    name: 'khieu_nai_bang_luong_chi_tiet',
    component: page('khieu-nai-bang-luong/detail.vue'),
    permissions: [],
    advanced_permissions: [],
  },

  {
    path: '/social-security-claim',
    name: 'social_security_claim',
    component: page('social-security-claim/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/social-security-claim/tao-moi',
    name: 'social_security_claim.tao-moi',
    component: page('social-security-claim/tao-moi.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/social-security-claim/:id/chi-tiet',
    name: 'social_security_claim.chi-tiet',
    component: page('social-security-claim/chi-tiet.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/social-security-claim/:id/duyet',
    name: 'social-security-claim.duyet',
    component: page('social-security-claim/approve.vue'),
    permissions: [],
    advanced_permissions: [],
  },

  {
    path: '/thong-bao-chi-tiet',
    name: 'thong-bao-chi-tiet',
    component: page('thong-bao/chi-tiet.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/danh-gia-ban-than',
    name: 'danh_gia_ban_than',
    component: page('quan-ly-danh-gia/danh-gia-ban-than.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/hop-dong-lao-dong',
    name: 'hop_dong_lao_dong',
    component: page('nhan-vien/ky-hop-dong.vue'),
    permissions: [],
    advanced_permissions: [],
  },

  // Cong ty
  {
    path: '/nhan-vien',
    name: 'nhan_vien',
    component: page('nhan-vien/index.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-list-read'],
  },
  {
    path: '/nhan-vien/tao-moi',
    name: 'nhan_vien.tao_moi',
    component: page('nhan-vien/tao-moi.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-list-create'],
  },
  {
    path: '/nhan-vien/hop-dong-lao-dong',
    name: 'nhan_vien.hop_dong_lao_dong',
    component: page('nhan-vien/hop-dong-lao-dong.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-list-read'],
  },

  {
    path: '/nhan-vien/:id/thong-tin-co-ban',
    name: 'nhan_vien.chi_tiet.thong_tin_co_ban',
    component: page('nhan-vien/chi-tiet/thong-tin-co-ban.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-list-read'],
  },
  {
    path: '/nhan-vien/:id/thong-tin-khac',
    name: 'nhan_vien.chi_tiet.thong_tin_khac',
    component: page('nhan-vien/chi-tiet/thong-tin-khac.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-list-read'],
  },
  {
    path: '/nhan-vien/:id/thong-tin-luong',
    name: 'nhan_vien.chi_tiet.thong_tin_luong',
    component: page('nhan-vien/chi-tiet/thong-tin-luong.vue'),
    permissions: ['manage-employee-payroll'],
    advanced_permissions: ['advanced-manage-payroll-info-read'],
  },
  {
    path: '/nhan-vien/:id/quyen-truy-cap',
    name: 'nhan_vien.chi_tiet.quyen_truy_cap',
    component: page('nhan-vien/chi-tiet/quyen-truy-cap.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-list-read'],
  },
  {
    path: '/nhan-vien/:id/thong-tin-tai-khoan',
    name: 'nhan_vien.chi_tiet.thong_tin_tai_khoan',
    component: page('nhan-vien/chi-tiet/thong-tin-tai-khoan.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-list-read'],
  },
  {
    path: '/nhan-vien/:id/thong-tin-bhxh',
    name: 'nhan_vien.chi_tiet.thong_tin_bhxh',
    component: page('nhan-vien/chi-tiet/thong-tin-bhxh.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['manage-employee'],
  },
  {
    path: '/nhan-vien/:id/timesheet',
    name: 'nhan_vien.chi_tiet.timesheet',
    component: page('nhan-vien/chi-tiet/timesheet.vue'),
    permissions: ['manage-employee', 'manage-timesheet'],
    advanced_permissions: ['advanced-manage-timesheet-working-update'],
  },
  {
    path: '/nhan-vien/:id/giay-phep-lao-dong',
    name: 'nhan_vien.chi_tiet.giay_phep_lao_dong',
    component: page('nhan-vien/chi-tiet/giay-phep-lao-dong.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-list-read'],
  },
  {
    path: '/danh-sach-don-nguoi-phu-thuoc',
    name: 'danh_sach_don_nguoi_phu_thuoc',
    component: page('nhan-vien/danh-sach-don-nguoi-phu-thuoc.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/ho-so-nguoi-phu-thuoc',
    name: 'ho_so_nguoi_phu_thuoc',
    component: page('nhan-vien/ho-so-nguoi-phu-thuoc.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/nhom-nhan-vien',
    name: 'nhom_nhan_vien',
    component: page('nhan-vien/group.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-group-read'],
  },
  {
    path: '/quan-ly-tai-khoan',
    name: 'quan_ly_tai_khoan',
    component: page('nhan-vien/quan-ly-tai-khoan.vue'),
    permissions: [],
    advanced_permissions: ['manage_permission'],
  },
  {
    path: '/bang-luong',
    name: 'bang_luong',
    component: page('bang-luong/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  {
    path: '/bang-luong/tao-moi',
    name: 'bang_luong.tao_moi',
    component: page('bang-luong/tao-moi.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  {
    path: '/bang-luong/preview',
    name: 'bang_luong.preview',
    component: page('bang-luong/preview.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  // {
  //   path: '/bang-luong/tao-bang-tinh-luong-thu',
  //   name: 'bang_luong.tao_bang_tinh_luong_thu',
  //   component: page('bang-luong/tao-bang-tinh-luong-thu.vue'),
  //   permissions: ['permission_sample_payroll'],
  //   advanced_permissions: ['permission_sample_payroll'],
  // },
  {
    path: '/bang-luong/export-preview-payslip',
    name: 'bang_luong.export_preview_payslip',
    component: page('bang-luong/export-payslip-preview-print.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  {
    path: '/bang-luong/:id/chi-tiet',
    name: 'bang_luong.chi_tiet',
    component: page('bang-luong/chi-tiet.vue'),
    permissions: ['manage-payroll', 'CLIENT_REQUEST_PAYROLL'],
    advanced_permissions: ['advanced-manage-payroll-list-read'],
  },

  {
    path: '/payslip-template-preview/:id',
    name: 'payslip_template_preview',
    component: page('bang-luong/payslip-template-preview.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },

  {
    path: '/duyet-dang-ky-cong-so-nghi-phep/:id',
    name: 'duyet_dang_ky_cong_so_nghi_phep',
    component: page('dang-ky-cong-so-nghi-phep/approve.vue'),
    permissions: ['CLIENT_REQUEST_OFF', 'CLIENT_REQUEST_CONG_TAC'],
    advanced_permissions: ['CLIENT_REQUEST_OFF', 'CLIENT_REQUEST_CONG_TAC'],
  },
  {
    path: '/duyet-dang-ky-cong-so-ot/:id',
    name: 'duyet_dang_ky_cong_so_ot',
    component: page('dang-ky-cong-so-ot/approve.vue'),
    permissions: ['CLIENT_REQUEST_OT'],
    advanced_permissions: ['CLIENT_REQUEST_OT'],
  },
  {
    path: '/quan-ly-dieu-chinh-gio-lam-thuc-te',
    name: 'quan_ly_dieu_chinh_gio_lam_thuc_te',
    component: page('quan-ly-dieu-chinh-gio-lam-thuc-te/index.vue'),
    permissions: ['manage-timesheet'],
    advanced_permissions: ['advanced-manage-timesheet-adjust-hours-read'],
  },
  {
    path: '/quan-ly-dang-ky-cong-so-leave',
    name: 'quan_ly_dang_ky_cong_so_leave',
    component: page('quan-ly-dang-ky-cong-so/leave.vue'),
    permissions: ['CLIENT_REQUEST_OFF', 'manage-timesheet'],
    advanced_permissions: ['advanced-manage-timesheet-leave-read'],
  },
  {
    path: '/quan-ly-dang-ky-cong-so-ot',
    name: 'quan_ly_dang_ky_cong_so_ot',
    component: page('quan-ly-dang-ky-cong-so/ot.vue'),
    permissions: ['CLIENT_REQUEST_OT', 'manage-timesheet'],
    advanced_permissions: ['advanced-manage-timesheet-overtime-read'],
  },
  {
    path: '/quan-ly-dang-ky-cong-so-cong-tac',
    name: 'quan_ly_dang_ky_cong_so_cong_tac',
    component: page('quan-ly-dang-ky-cong-so/congtac.vue'),
    permissions: ['CLIENT_REQUEST_CONG_TAC', 'manage-timesheet'],
    advanced_permissions: [
      'advanced-manage-timesheet-outside-working-wfh-read',
    ],
  },
  {
    path: '/ho-so-ca-nhan/thong-tin-co-ban',
    name: 'ho_so_ca_nhan.thong_tin_co_ban',
    component: page('ho-so-ca-nhan/thong-tin-co-ban.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/ho-so-ca-nhan/thong-tin-tai-khoan',
    name: 'ho_so_ca_nhan.thong-tin-tai-khoan',
    component: page('ho-so-ca-nhan/thong-tin-tai-khoan.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/ho-so-ca-nhan/thong-tin-luong',
    name: 'ho_so_ca_nhan.thong_tin_luong',
    component: page('ho-so-ca-nhan/thong-tin-luong.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/ho-so-ca-nhan/thong-tin-khac',
    name: 'ho_so_ca_nhan.thong_tin_khac',
    component: page('ho-so-ca-nhan/thong-tin-khac.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/ho-so-ca-nhan/thong-tin-bhxh',
    name: 'ho_so_ca_nhan.thong_tin_bhxh',
    component: page('ho-so-ca-nhan/thong-tin-bhxh.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/ho-so-ca-nhan/lao-dong-nuoc-ngoai',
    name: 'ho_so_ca_nhan.lao_dong_nuoc_ngoai',
    component: page('ho-so-ca-nhan/lao-dong-nuoc-ngoai.vue'),
    permissions: [],
    advanced_permissions: [],
  },

  // TODO
  {
    path: '/quan-ly-bang-luong-ke-toan',
    name: 'quan_ly_bang_luong_ke_toan',
    component: page('quan-ly-bang-luong-ke-toan/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['advanced-manage-payroll-list-read'],
  },
  {
    path: '/quan-ly-bang-luong-quan-ly',
    name: 'quan_ly_bang_luong_quan_ly',
    component: page('quan-ly-bang-luong-quan-ly/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: [
      'advanced-manage-payroll-info-read',
      'advanced-manage-payroll-list-read',
    ],
  },
  {
    path: '/quan-ly-khieu-nai-bang-luong',
    name: 'quan_ly_khieu_nai_bang_luong',
    component: page('quan-ly-khieu-nai-bang-luong/index.vue'),
    permissions: ['manage-payroll-complaint'],
    advanced_permissions: ['manage-payroll-complaint'],
  },
  {
    path: '/quan-ly-bao-cao-pit',
    name: 'quan_ly_bao_cao_pit',
    component: page('quan-ly-bao-cao-pit/statics.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  {
    path: '/quan-ly-bao-cao-pit/danh-sach',
    name: 'quan_ly_bao_cao_pit.danh_sach',
    component: page('quan-ly-bao-cao-pit/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },

  // {
  //   path: '/quan-ly-debitnote',
  //   name: 'quan_ly_debitnote',
  //   component: page('quan-ly-debitnote/index.vue'),
  //   permissions: ['manage-payroll'],
  //   advanced_permissions: ['advanced-manage-payroll-list-read'],
  // },
  {
    path: '/quan-ly-ke-khai-bao-hiem',
    name: 'quan_ly_ke_khai_bao_hiem',
    component: page('quan-ly-ke-khai-bao-hiem/index.vue'),
    permissions: ['CLIENT_REQUEST_SOCIAL_SECURITY_PROFILE', 'manage-social'],
    advanced_permissions: ['advanced-manage-payroll-social-declaration-read'],
  },
  {
    path: '/quan-ly-ke-khai-bao-hiem/tao-moi',
    name: 'quan_ly_ke_khai_bao_hiem_tao_moi',
    component: page('quan-ly-ke-khai-bao-hiem/create.vue'),
    permissions: ['CLIENT_REQUEST_SOCIAL_SECURITY_PROFILE', 'manage-social'],
    advanced_permissions: ['advanced-manage-payroll-social-declaration-create'],
  },
  {
    path: '/quan-ly-ke-khai-bao-hiem/:id/chi-tiet',
    name: 'quan_ly_ke_khai_bao_hiem_chi_tiet',
    component: page('quan-ly-ke-khai-bao-hiem/chi-tiet.vue'),
    permissions: ['CLIENT_REQUEST_SOCIAL_SECURITY_PROFILE', 'manage-social'],
    advanced_permissions: ['advanced-manage-payroll-social-declaration-update'],
  },
  {
    path: '/quan-ly-ke-khai-bao-hiem/:id/approve',
    name: 'quan_ly_ke_khai_bao_hiem_approve',
    component: page('quan-ly-ke-khai-bao-hiem/approve.vue'),
    permissions: ['CLIENT_REQUEST_SOCIAL_SECURITY_PROFILE', 'manage-social'],
    advanced_permissions: [
      'advanced-manage-payroll-social-declaration-read',
      'advanced-manage-payroll-social-declaration-update',
    ],
  },
  {
    path: '/quan-ly-nop-ho-so',
    name: 'quan_ly_nop_ho_so',
    component: page('quan-ly-nop-ho-so/index.vue'),
    permissions: ['permission_apply_document'],
    advanced_permissions: ['permission_apply_document'],
  },
  {
    path: '/quan-ly-nop-ho-so/:id/chi-tiet',
    name: 'quan_ly_nop_ho_so.chi_tiet',
    component: page('quan-ly-nop-ho-so/chi-tiet.vue'),
    permissions: ['permission_apply_document'],
    advanced_permissions: ['permission_apply_document'],
  },
  {
    path: '/quan-ly-nop-ho-so/tao-moi',
    name: 'quan_ly_nop_ho_so.tao_moi',
    component: page('quan-ly-nop-ho-so/tao-moi.vue'),
    permissions: ['permission_apply_document'],
    advanced_permissions: ['permission_apply_document'],
  },
  {
    path: '/quan-ly-dinh-nghia-bien',
    name: 'quan_ly_dinh_nghia_bien',
    component: page('quan-ly-dinh-nghia-bien/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  {
    path: '/quan-ly-template-excel',
    name: 'quan_ly_template_excel',
    component: page('quan-ly-template-excel/index.vue'),
    permissions: ['manage-timesheet'],
    advanced_permissions: ['advanced-manage-timesheet-working-update'],
  },
  {
    path: '/quan-ly-lich-lam-viec',
    name: 'quan_ly_lich_lam_viec',
    component: page('quan-ly-lich-lam-viec/index.vue'),
    permissions: ['manage-workschedule'],
    advanced_permissions: ['manage-workschedule'],
  },
  {
    path: '/quan-ly-lich-lam-viec/:templateId',
    name: 'quan_ly_lich_lam_viec.danh_sach',
    component: page('quan-ly-lich-lam-viec/danh-sach.vue'),
    permissions: ['manage-workschedule'],
    advanced_permissions: ['manage-workschedule'],
  },
  {
    path: '/quan-ly-dieu-kien-so-sanh',
    name: 'quan_ly_dieu_kien_so_sanh',
    component: page('quan-ly-dieu-kien-so-sanh/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  {
    path: '/quan-ly-cau-hinh-chuc-nang',
    name: 'quan_ly_cau_hinh_chuc_nang',
    component: page('quan-ly-cau-hinh-chuc-nang/index.vue'),
    permissions: ['manage-workschedule'],
  },
  {
    path: '/yeu-cau-ho-tro',
    name: 'yeu_cau_ho_tro',
    component: page('yeu-cau-ho-tro/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: page('FAQ/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/FAQ/:title',
    name: 'FAQ_detail',
    component: page('FAQ/detail.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/bao-cao-tong-hop',
    name: 'bao_cao_tong_hop',
    component: page('bao-cao-tong-hop/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },

  {
    path: '/lich-su-chuc-vu',
    name: 'lich_su_chuc_vu',
    component: page('lich-su-chuc-vu/index.vue'),
    permissions: ['manage-employee'],
    advanced_permissions: ['advanced-manage-employee-history-position-read'],
  },
  {
    path: '/lich-su-luong',
    name: 'lich_su_luong',
    component: page('lich-su-luong/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: [
      'advanced-manage-payroll-info-read',
      'advanced-manage-payroll-salary-history-read',
    ],
  },
  {
    path: '/yeu-cau-thay-doi-thong-tin',
    name: 'yeu-cau-thay-doi-thong-tin',
    component: page('yeu-cau-thay-doi-thong-tin/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },

  {
    path: '/quan-ly-timesheet',
    name: 'quan_ly_timesheet',
    component: page('quan-ly-timesheet/index.vue'),
    permissions: ['manage-timesheet', 'CLIENT_REQUEST_TIMESHEET'],
    advanced_permissions: ['advanced-manage-timesheet-working-read'],
  },
  {
    path: '/quan-ly-timesheet/:id',
    name: 'quan_ly_timesheet.detail',
    component: page('quan-ly-timesheet/detail.vue'),
    permissions: ['manage-timesheet', 'CLIENT_REQUEST_TIMESHEET'],
    advanced_permissions: ['advanced-manage-timesheet-working-read'],
  },
  {
    path: '/quan-ly-timesheet/:id/export-timesheet',
    name: 'quan_ly_timesheet.export_timesheet',
    component: page('quan-ly-timesheet/export-timesheet.vue'),
    permissions: ['manage-timesheet', 'CLIENT_REQUEST_TIMESHEET'],
    advanced_permissions: [
      'advanced-manage-timesheet-working-read',
      'advanced-manage-timesheet-working-export',
    ],
  },
  {
    path: '/quan-ly-timesheet/:id/export-timesheet/print',
    name: 'quan_ly_timesheet.export_timesheet.print',
    component: page('quan-ly-timesheet/export-timesheet-print.vue'),
    permissions: ['manage-timesheet', 'CLIENT_REQUEST_TIMESHEET'],
    advanced_permissions: [
      'advanced-manage-timesheet-working-read',
      'advanced-manage-timesheet-working-import',
    ],
  },
  {
    path: '/quan-ly-dang-ky-cong-so-tong-hop',
    name: 'quan_ly_dang_ky_cong_so_tong_hop',
    component: page('quan-ly-dang-ky-cong-so/summary.vue'),
    permissions: ['manage-timesheet'],
    advanced_permissions: ['advanced-manage-timesheet-summary-read'],
  },
  {
    path: '/quan-ly-danh-sach-ca',
    name: 'quan_ly_danh_sach_ca',
    component: page('quan-ly-danh-sach-ca/index.vue'),
    permissions: ['manage-timesheet'],
    advanced_permissions: ['advanced-manage-timesheet-timesheet-shift-read'],
  },
  // {
  //   path: '/quan-ly-lich-su-checkin',
  //   name: 'quan_ly_lich_su_checkin',
  //   component: page('quan-ly-lich-su-checkin/index.vue'),
  //   permissions: [],
  //   advanced_permissions: [],
  // },
  {
    path: '/quan-ly-social-security-claim',
    name: 'quan_ly_social_security_claim',
    component: page('quan-ly-social-security-claim/index.vue'),
    permissions: ['CLIENT_REQUEST_CLAIM_BHXH', 'manage-social'],
    advanced_permissions: ['advanced-manage-payroll-social-insurance-read'],
  },
  {
    path: '/quan-ly-social-security-claim/:id/chi-tiet',
    name: 'quan_ly_social_security_claim.chi-tiet',
    component: page('quan-ly-social-security-claim/chi-tiet.vue'),
    permissions: ['CLIENT_REQUEST_CLAIM_BHXH', 'manage-social'],
    advanced_permissions: ['advanced-manage-payroll-social-insurance-update'],
  },
  {
    path: '/quan-ly-social-security-claim/tao-moi',
    name: 'quan_ly_social_security_claim.tao-moi',
    component: page('quan-ly-social-security-claim/tao-moi.vue'),
    permissions: ['CLIENT_REQUEST_CLAIM_BHXH', 'manage-social'],
    advanced_permissions: ['advanced-manage-payroll-social-insurance-create'],
  },

  {
    path: '/change-language/:id',
    name: 'change_language',
    component: page('change-language.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/yeu-cau-duyet',
    name: 'yeu_cau_duyet',
    component: page('yeu-cau-duyet/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/wifi-checkin-spot',
    name: 'wifi_checkin_spot',
    component: page('wifi-checkin-spot/index.vue'),
    permissions: ['manage-wifi-checkin'],
    advanced_permissions: ['manage-wifi-checkin'],
  },
  {
    path: '/wifi-checkin-spot/tao-moi',
    name: 'wifi_checkin_spot.tao_moi',
    component: page('wifi-checkin-spot/create.vue'),
    permissions: ['manage-wifi-checkin'],
    advanced_permissions: ['manage-wifi-checkin'],
  },
  {
    path: '/wifi-checkin-spot/sua/:id',
    name: 'wifi_checkin_spot.sua',
    component: page('wifi-checkin-spot/sua.vue'),
    permissions: ['manage-wifi-checkin'],
    advanced_permissions: ['manage-wifi-checkin'],
  },
  {
    path: '/wifi-checkin-spot/:id/chi-tiet',
    name: 'wifi_checkin_spot.chi_tiet',
    component: page('wifi-checkin-spot/chi-tiet.vue'),
    permissions: ['manage-wifi-checkin'],
    advanced_permissions: ['manage-wifi-checkin'],
  },
  {
    path: '/location-checkin',
    name: 'location_checkin',
    component: page('location-checkin/index.vue'),
    permissions: ['manage-location-checkin'],
  },
  {
    path: '/location-checkin/tao-moi',
    name: 'location_checkin.tao_moi',
    component: page('location-checkin/create.vue'),
    permissions: ['manage-location-checkin'],
  },
  {
    path: '/location-checkin/sua/:id',
    name: 'location_checkin.sua',
    component: page('location-checkin/sua.vue'),
    permissions: ['manage-location-checkin'],
  },
  {
    path: '/quan-ly-dao-tao',
    name: 'quan_ly_dao_tao',
    component: page('quan-ly-dao-tao/index.vue'),
    permissions: ['manage-training'],
    advanced_permissions: ['manage-training'],
  },
  {
    path: '/quan-ly-dao-tao/diem-danh/:id',
    name: 'quan_ly_dao_tao_diem_danh',
    component: page('quan-ly-dao-tao/attendance.vue'),
    permissions: ['manage-training'],
    advanced_permissions: ['manage-training'],
  },
  // {
  //   path: '/quan-ly-giao-vien',
  //   name: 'quan_ly_giao_vien',
  //   component: page('quan-ly-dao-tao/teacher.vue'),
  //   permissions: ['manage-training'],
  // },
  {
    path: '/quan-ly-dao-tao/lich-su',
    name: 'quan_ly_lich_su_dao_tao',
    component: page('quan-ly-dao-tao/history.vue'),
    permissions: ['manage-training'],
    advanced_permissions: ['manage-training'],
  },
  {
    path: '/quan-ly-dao-tao/:id',
    name: 'quan_ly_dao_tao_chi_tiet',
    component: page('quan-ly-dao-tao/detail.vue'),
    permissions: ['manage-training'],
    advanced_permissions: ['manage-training'],
  },
  {
    path: '/quan-ly-dao-tao/lich-su/:id',
    name: 'quan_ly_dao_tao_lich_su_chi_tiet',
    component: page('quan-ly-dao-tao/historyDetail.vue'),
    permissions: ['manage-training'],
    advanced_permissions: ['manage-training'],
  },
  {
    path: '/quan-ly-hop-dong',
    name: 'quan_ly_hop_dong',
    component: page('quan-ly-hop-dong/index.vue'),
    permissions: ['manage-contract'],
    advanced_permissions: ['manage-contract'],
  },

  {
    path: '/quan-ly-hop-dong/tao-moi/:type',
    name: 'quan_ly_hop_dong_tao_moi',
    component: page('quan-ly-hop-dong/create.vue'),
    permissions: ['manage-contract'],
    advanced_permissions: ['manage-contract'],
  },
  {
    path: '/quan-ly-hop-dong/:id/chi-tiet',
    name: 'quan-ly-hop-dong.chi-tiet',
    component: page('quan-ly-hop-dong/chi-tiet.vue'),
    permissions: ['manage-contract'],
    advanced_permissions: ['manage-contract'],
  },
  {
    path: '/quan-ly-mau-hop-dong',
    name: 'quan_ly_mau_hop_dong',
    component: page('quan-ly-mau-hop-dong/index.vue'),
    permissions: ['manage-contract'],
    advanced_permissions: ['manage-contract'],
  },
  {
    path: '/quan-ly-mau-hop-dong/tao-moi',
    name: 'quan_ly_mau_hop_dong_tao_moi',
    component: page('quan-ly-mau-hop-dong/create.vue'),
    permissions: ['manage-contract'],
    advanced_permissions: ['manage-contract'],
  },
  {
    path: '/quan-ly-mau-hop-dong/:id/chi-tiet',
    name: 'quan_ly_mau_hop_dong_chi_tiet',
    component: page('quan-ly-mau-hop-dong/detail.vue'),
    permissions: ['manage-contract'],
    advanced_permissions: ['manage-contract'],
  },
  {
    path: '/quan-ly-danh-gia/tong-quan/:id',
    name: 'quan_ly_danh_gia.tong_quan',
    component: page('quan-ly-danh-gia/overview.vue'),
    permissions: ['manage-evaluation'],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-danh-gia',
    name: 'quan_ly_danh_gia',
    component: page('quan-ly-danh-gia/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-danh-gia/dot-danh-gia',
    name: 'quan_ly_danh_gia_dot_danh_gia',
    component: page('quan-ly-danh-gia/dot-danh-gia.vue'),
    permissions: ['manage-evaluation'],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-danh-gia/dot-danh-gia/tao_moi',
    name: 'quan_ly_danh_gia_tao_moi',
    component: page('quan-ly-danh-gia/tao_moi.vue'),
    permissions: ['manage-evaluation'],
    advanced_permissions: [],
  },
  {
    path: '/danh-gia-nhan-su',
    name: 'danh_gia_nhan_su',
    component: page('quan-ly-danh-gia/danh-gia-nhan-su.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/mau-cau-hoi',
    name: 'mau_cau_hoi',
    component: page('quan-ly-danh-gia/TemplateQuestion/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/dao-tao',
    name: 'dao_tao',
    component: page('quan-ly-dao-tao/employeesTraining.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/dao-tao/:id',
    name: 'dao_tao_chi_tiet',
    component: page('quan-ly-dao-tao/employeesDetail.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/danh-gia',
    name: 'danh_gia',
    component: page('quan-ly-danh-gia/employeesEvaluation.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/danh-gia-ban-than/:id/:evaluation/:employee',
    name: 'danh_gia_ban_than_chi_tiet',
    component: page('quan-ly-danh-gia/danh-gia.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-phong-ban',
    name: 'quan_ly_phong_ban',
    component: page('quan-ly-phong-ban/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-chuc-vu',
    name: 'quan_ly_chuc_vu',
    component: page('quan-ly-chuc-vu/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-tin-tuyen-dung',
    name: 'quan_ly_tin_tuyen_dung',
    component: page('quan-ly-tin-tuyen-dung/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-tin-tuyen-dung/tao-moi',
    name: 'quan_ly_tin_tuyen_dung.tao_moi',
    component: page('quan-ly-tin-tuyen-dung/tao-moi.vue'),
    permissions: ['manage-jobboard'],
    advanced_permissions: ['manage-jobboard'],
  },
  {
    path: '/quan-ly-tin-tuyen-dung/quy-trinh-tuyen-dung/:id',
    name: 'quan_ly_tin_tuyen_dung.quy_trinh_tuyen_dung',
    component: page('quan-ly-tin-tuyen-dung/quy-trinh-tuyen-dung.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-mau-tinh-luong',
    name: 'quan_ly_mau_tinh_luong',
    component: page('quan-ly-mau-tinh-luong/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  {
    path: '/quan-ly-mau-tinh-luong/tao-moi',
    name: 'quan_ly_mau_tinh_luong.tao_moi',
    component: page('quan-ly-mau-tinh-luong/tao-moi.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  {
    path: '/quan-ly-mau-tinh-luong/:id/chi-tiet',
    name: 'quan_ly_mau_tinh_luong.chi_tiet',
    component: page('quan-ly-mau-tinh-luong/chi-tiet.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },
  {
    path: '/quan-ly-mau-tinh-luong/:id/payslip',
    name: 'quan_ly_mau_tinh_luong.payslip',
    component: page('quan-ly-mau-tinh-luong/payslip.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },

  {
    path: '/quan-ly-template-bang-luong',
    name: 'quan_ly_template_bang_luong',
    component: page('quan-ly-template-bang-luong/index.vue'),
    permissions: ['manage-payroll'],
    advanced_permissions: ['manage-payroll'],
  },

  {
    path: '/quan-ly-template-yeu-cau-thanh-toan',
    name: 'quan_ly_template_yeu_cau_thanh_toan',
    component: page('quan-ly-template-yeu-cau-thanh-toan/index.vue'),
    permissions: ['manage-payment-request'],
    advanced_permissions: ['manage-payment-request'],
  },

  // {
  //   path: '/quan-ly-mau-bao-cao-ke-toan',
  //   name: 'quan_ly_mau_bao_cao_ke_toan',
  //   component: page('quan-ly-mau-bao-cao-ke-toan/index.vue'),
  //   permissions: ['manage-payroll'],
  //   advanced_permissions: ['manage-payroll'],
  // },
  // {
  //   path: '/quan-ly-mau-bao-cao-ke-toan/tao-moi',
  //   name: 'quan_ly_mau_bao_cao_ke_toan.tao_moi',
  //   component: page('quan-ly-mau-bao-cao-ke-toan/tao-moi.vue'),
  //   permissions: ['manage-payroll'],
  //   advanced_permissions: ['manage-payroll'],
  // },
  // {
  //   path: '/quan-ly-mau-bao-cao-ke-toan/:id/chi-tiet',
  //   name: 'quan_ly_mau_bao_cao_ke_toan.chi_tiet',
  //   component: page('quan-ly-mau-bao-cao-ke-toan/chi-tiet.vue'),
  //   permissions: ['manage-payroll'],
  //   advanced_permissions: ['manage-payroll'],
  // },
  // {
  //   path: '/quan-ly-mau-bao-cao-ke-toan/mau-bao-cao',
  //   name: 'quan_ly_mau_bao_cao_ke_toan.mau_bao_cao',
  //   component: page('quan-ly-mau-bao-cao-ke-toan/mau-bao-cao.vue'),
  //   permissions: ['manage-payroll'],
  //   advanced_permissions: ['manage-payroll'],
  // },
  {
    path: '/quan-ly-cong-thuc',
    name: 'quan_ly_cong_thuc',
    component: page('quan-ly-cong-thuc/index.vue'),
    permissions: ['manage-formula'],
    advanced_permissions: ['manage-formula'],
  },
  {
    path: '/quan-ly-cong-thuc/tao-moi',
    name: 'quan_ly_cong_thuc.tao_moi',
    component: page('quan-ly-cong-thuc/tao-moi.vue'),
    permissions: ['manage-formula'],
    advanced_permissions: ['manage-formula'],
  },
  {
    path: '/quan-ly-cong-thuc/sua/:id',
    name: 'quan_ly_cong_thuc.sua',
    component: page('quan-ly-cong-thuc/sua.vue'),
    permissions: ['manage-formula'],
    advanced_permissions: ['manage-formula'],
  },
  {
    path: '/quan-ly-tin-tuyen-dung/applications/:id',
    name: 'quan_ly_tin_tuyen_dung.applications',
    component: page('quan-ly-tin-tuyen-dung/jobboard_applications.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-tin-tuyen-dung/applications/:parentId/chi-tiet/:id',
    name: 'quan_ly_tin_tuyen_dung.application',
    component: page('quan-ly-tin-tuyen-dung/jobboard_application.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-phan-quyen/thiet-lap-flow',
    name: 'thiet_lap_flow',
    component: page('quan-ly-phan-quyen/thiet-lap-flow.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-phan-quyen/thiet-lap-luong-duyet-nhom',
    name: 'thiet_lap_luong_duyet_nhom',
    component: page('quan-ly-phan-quyen/thiet-lap-luong-duyet-nhom.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/quan-ly-phan-quyen/phan-quyen-he-thong',
    name: 'phan_quyen_he_thong',
    component: page('quan-ly-phan-quyen/phan-quyen-he-thong.vue'),
    permissions: [],
    advanced_permissions: ['manage_permission'],
  },

  {
    path: '/camera-device',
    name: 'camera_device',
    component: page('camera-device/index.vue'),
    permissions: ['manage-camera-checkin'],
    advanced_permissions: ['manage-camera-checkin'],
  },
  {
    path: '/hanet-ai',
    name: 'hanet_ai',
    component: page('hanet-ai/index.vue'),
    permissions: ['manage-camera-checkin'],
    advanced_permissions: ['manage-camera-checkin'],
  },
  {
    path: '/hanet-ai-callback',
    name: 'hanet_ai_callback',
    component: page('hanet-ai/callback.vue'),
    permissions: ['manage-camera-checkin'],
    advanced_permissions: ['manage-camera-checkin'],
  },
  {
    path: '/timesheet-linh-hoat',
    name: 'timesheet_linh_hoat',
    component: page('timesheet-linh-hoat/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },

  {
    path: '/my-project',
    name: 'my_project',
    component: page('project-management/myproject.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/my-project/:id',
    name: 'my_project.detail',
    component: page('project-management/myproject-detail.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  {
    path: '/project-management',
    name: 'project_management',
    component: page('project-management/index.vue'),
    permissions: ['manage-project'],
    advanced_permissions: ['manage-project'],
  },
  {
    path: '/project-management/:id',
    name: 'project_management.detail',
    component: page('project-management/detail.vue'),
    permissions: ['manage-project'],
    advanced_permissions: ['manage-project'],
  },
  {
    path: '/client-management',
    name: 'project_client_management',
    component: page('project-management/clients.vue'),
    permissions: ['manage-project'],
    advanced_permissions: ['manage-project'],
  },

  {
    path: '/yeu-cau-thanh-toan',
    name: 'yeu_cau_thanh_toan',
    component: page('yeu-cau-thanh-toan/index.vue'),
    permissions: [],
    advanced_permissions: [],
  },
  // {
  //   path: '/action-log',
  //   name: 'action_log',
  //   component: page('action-log/index.vue'),
  //   permissions: [],
  // },
  // { path: '/yeu-cau-thanh-toan/chi-tiet', name: 'yeu_cau_thanh_toan_chi_tiet', component: page('yeu-cau-thanh-toan/chi-tiet.vue'), permissions:[] },
  {
    path: '/quan-ly-yeu-cau-thanh-toan',
    name: 'quan_ly_yeu_cau_thanh_toan',
    component: page('quan-ly-yeu-cau-thanh-toan/index.vue'),
    permissions: ['manage-payment-request'],
    advanced_permissions: ['manage-payment-request'],
  },
  {
    path: '/quan-ly-nha-cung-cap',
    name: 'quan_ly_nha_cung_cap',
    component: page('quan-ly-nha-cung-cap/index.vue'),
    permissions: ['manage-payment-request'],
    advanced_permissions: ['manage-payment-request'],
  },
  {
    path: '/nha-cung-cap',
    name: 'nha_cung_cap',
    component: page('nha-cung-cap/index.vue'),
    permissions: [],
  },
  {
    path: '/duyet-yeu-cau-thanh-toan/:id',
    name: 'duyet_yeu_cau_thanh_toan',
    component: page('yeu-cau-thanh-toan/approve.vue'),
    permissions: ['manage-payment-request'],
    advanced_permissions: ['manage-payment-request'],
  },
  {
    path: '/thu-vien-cau-hoi',
    name: 'thu_vien_cau_hoi',
    component: page('hoi-dap/index.vue'),
  },
  {
    path: '/thu-vien-cau-hoi/chi-tiet',
    name: 'thu_vien_cau_hoi_chi_tiet',
    component: page('hoi-dap/detail.vue'),
  },
  {
    path: '/thong-ke-lam-them-gio',
    name: 'thong-ke-lam-them-gio',
    component: page('thong-ke-lam-them-gio/index.vue'),
    permissions: ['CLIENT_REQUEST_OT'],
    advanced_permissions: ['advanced-manage-timesheet-overtime-read'],
  },
  {
    path: '/thiet-lap-dang-ki-cong-so',
    name: 'thiet_lap_dang_ki_cong_so',
    component: page('thiet-lap/dang-ki-cong-so.vue'),
    permissions: ['manage-workschedule'],
    advanced_permissions: ['manage-workschedule'],
  },
  {
    path: '/thiet-lap-nghi-phep',
    name: 'thiet_lap_nghi_phep',
    component: page('thiet-lap/thiet-lap-nghi-phep.vue'),
    permissions: ['manage-workschedule'],
    advanced_permissions: ['manage-workschedule'],
  },
  {
    path: '/quan-ly-ca',
    name: 'quan_ly_ca',
    component: page('quan-ly-ca/QuanLyCa.vue'),
    permissions: ['manage-workschedule'],
    advanced_permissions: ['manage-workschedule'],
  },
  {
    path: '/quan-ly-ngay-le',
    name: 'quan_ly_ngay_le',
    component: page('quan-ly-ngay-le/index.vue'),
    permissions: ['manage-workschedule'],
    advanced_permissions: ['manage-workschedule'],
  },
  {
    path: '/thiet-lap-tang-ca',
    name: 'thiet_lap_tang_ca',
    component: page('thiet-lap/thiet-lap-tang-ca.vue'),
    permissions: ['manage-workschedule'],
    advanced_permissions: ['manage-workschedule'],
  },
  {
    path: '/phien-ban/:id',
    name: 'phien-ban',
    component: page('web-version/index.vue'),
  },
  {
    path: '/mau-dao-tao',
    name: 'quan_ly_mau_dao_tao',
    component: page('quan-ly-dao-tao/TrainingTemplate/index.vue'),
    permissions: ['manage-training'],
    advanced_permissions: [],
  },
  {
    path: '/dien-gia-dao-tao',
    name: 'quan_ly_dien_gia_dao_tao',
    component: page('quan-ly-dao-tao/TrainingTeacher/index.vue'),
    permissions: ['manage-training'],
    advanced_permissions: [],
  },
  {
    path: '/dia-diem-dao-tao',
    name: 'quan_ly_dia_diem_dao_tao',
    component: page('quan-ly-dao-tao/TrainingLocation/index.vue'),
    permissions: ['manage-training'],
    advanced_permissions: [],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: page('dashboard/index.vue'),

  },
  {
    path: '/quan-ly-thong-bao',
    name: 'quan_ly_thong_bao',
    component: page('quan-ly-thong-bao/index.vue'),
    permissions: ['manage-workschedule'],
    advanced_permissions: ['manage-workschedule'],
  },
  {
    path: '/quan-ly-mau-cong-ca',
    name: 'quan_ly_mau_cong_ca',
    component: page('quan-ly-ca/timesheet-shift-template/index.vue'),
    permissions: ['manage-workschedule'],
    advanced_permissions: ['manage-workschedule'],
  },
]

export function getRoutePermissions(routeName, store) {
  const r = routes.find((v) => v.name === routeName)
  const s = store.state.auth.clientWorkflowSetting
  if(s.advanced_permission_flow){
    if (r && r.advanced_permissions) {
      return r.advanced_permissions
    }
    return []
  } else {
    if (r && r.permissions) {
      return r.permissions
    }
    return []
  }
}

export function createRouter() {
  const router = new Router({
    routes,
    mode: 'history',
  })
  /**
   * Raygun tracking
   */
  router.afterEach((to, from) => {
    if (typeof rg4js == 'function') {
      rg4js('trackEvent', {
        type: 'pageView',
        path: to.path,
      })
    }
  })
  return router
}

export const state = () => ({
  timesheetShifts: [],
})

export const getters = {
  timesheetShifts: (state) => state.timesheetShifts,
}

export const mutations = {
  SET_TIMESHEET_SHIFTS(state, timesheetShifts) {
    state.timesheetShifts = timesheetShifts
  },
}

export const actions = {
  setTimesheetShifts({ commit }, payload) {
    commit('SET_TIMESHEET_SHIFTS', payload)
  },
}

import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faXmark,
  faGripDotsVertical,
  faPlusLarge,
  faMinusLarge,
} from '@fortawesome/pro-regular-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import {
  faPenNib,
  faInfoCircle,
  faBusinessTime,
  faBadgeDollar,
  faFileCertificate,
  faMoneyCheckEditAlt,
  faClipboardCheck,
  faFolderArrowDown,
  faArrowsRepeat,
  faUsers,
  faChartPie,
  faPlus,
  faUserPlus,
  faDiagramProject,
  faMoneyCheckDollarPen,
  faListCheck,
  faUserLock,
  faTasks,
  faGear,
  faSquareQuestion,
  faBooks,
  faUniversalAccess,
  faBellSlash,
  faAlarmPlus,
  faHouseLeave,
  faFileAlt,
  faHospitals,
  faPeopleArrows,
  faAnalytics,
  faChartBar,
  faSignIn,
  faSignature,
  faEnvelopeOpenDollar,
  faFileMedical,
  faFolders,
  faFileSignature,
  faUserCog,
  faChartNetwork,
  faWifi,
  faCctv,
  faMoneyCheck,
  faCalendarAlt,
  faCalendarStar,
  faCalendarDay,
  faFileSpreadsheet,
  faLightbulbOn,
  faText,
  faPencilRuler,
  faFilePdf,
  faFileExcel,
  faEye,
  faEyeSlash,
  faTrash,
  faBackward,
  faCloudDownloadAlt,
  faCopy,
  faUser,
  faCircleQuestion,
  faPersonCircleCheck,
  faCircleCheck,
  faChevronDown,
  faChevronUp,
  faPaperPlane,
  faPeopleRoof,
  faPen,
  faRefresh,
  faPersonChalkboard,
  faCommentLines,
  faCommentExclamation,
  faAddressBook,
  faClipboardList,
  faLaptopCode,
  faTableList,
  faBan,
  faCircleInfo,
  faSpinner,
  faClockRotateLeft,
  faFileContract,
  faTruckField,
  faCircleDollarToSlot,
  faSitemap,
  faCode,
  faIdCard,
  faRotate,
  faArrowDownWideShort,
  faUserGraduate,
  faClock,
  faCalendarTime,
  faFileExport,
  faFileImport,
  faFiles,
  faFilter,
  faRectangleHistory,
  faCircleXmark,
  faEllipsis,
  faPaperclip,
  faFolderGear,
  faFloppyDisk,
  faCircleExclamation,
  faCheck,
  faMinus,
  faBraille,
  faAngleRight,
  faAngleLeft,
  faEllipsisVertical,
  faArrowRight,
  faGrip,
  faLocationDot,
  faArrowUpRightFromSquare,
  faTimer,
  faPencil,
  faUserPen,
  faEnvelopeDot,
  faPaste,
  faBell,
  faHouseBlank,
  faFlag,
  faClipboard,
  faArrowLeft,
} from '@fortawesome/pro-light-svg-icons'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
  faXmark,
  faPenNib,
  faGripDotsVertical,
  faPlusLarge,
  faMinusLarge,
  faInfoCircle,
  faBusinessTime,
  faBadgeDollar,
  faFileCertificate,
  faMoneyCheckEditAlt,
  faClipboardCheck,
  faFolderArrowDown,
  faArrowsRepeat,
  faUsers,
  faChartPie,
  faPlus,
  faUserPlus,
  faDiagramProject,
  faMoneyCheckDollarPen,
  faListCheck,
  faTasks,
  faUserLock,
  faGear,
  faSquareQuestion,
  faBooks,
  faUniversalAccess,
  faBellSlash,
  faAlarmPlus,
  faHouseLeave,
  faFileAlt,
  faHospitals,
  faPeopleArrows,
  faAnalytics,
  faChartBar,
  faSignIn,
  faSignature,
  faEnvelopeOpenDollar,
  faFileMedical,
  faFolders,
  faFileSignature,
  faUserCog,
  faChartNetwork,
  faWifi,
  faCctv,
  faCalendarAlt,
  faCalendarStar,
  faCalendarDay,
  faFileSpreadsheet,
  faLightbulbOn,
  faText,
  faPencilRuler,
  faMoneyCheck,
  faFilePdf,
  faFileExcel,
  faEye,
  faEyeSlash,
  faTrash,
  faBackward,
  faCloudDownloadAlt,
  faCopy,
  faUser,
  faCircleQuestion,
  faPersonCircleCheck,
  faCircleCheck,
  faChevronDown,
  faChevronUp,
  faPaperPlane,
  faPeopleRoof,
  faPen,
  faRefresh,
  faPersonChalkboard,
  faCommentLines,
  faCommentExclamation,
  faAddressBook,
  faClipboardList,
  faLaptopCode,
  faTableList,
  faBan,
  faCircleInfo,
  faSpinner,
  faFileContract,
  faTruckField,
  faCircleDollarToSlot,
  faSitemap,
  faCode,
  faIdCard,
  faRotate,
  faClockRotateLeft,
  faUserGraduate,
  faClock,
  faCalendarTime,
  faFileExport,
  faFileImport,
  faRectangleHistory,
  faCircleXmark,
  faFiles,
  faPaperclip,
  faFilter,
  faArrowDownWideShort,
  faEllipsis,
  faFolderGear,
  faFloppyDisk,
  faCircleExclamation,
  faCheck,
  faAngleRight,
  faAngleLeft,
  faMinus,
  faBraille,
  faEllipsisVertical,
  faArrowRight,
  faGrip,
  faLocationDot,
  faArrowUpRightFromSquare,
  faTimer,
  faPencil,
  faUserPen,
  faPaste,
  faEnvelopeDot,
  faBell,
  faHouseBlank,
  faHouse,
  faFlag,
  faClipboard,
  faArrowLeft,
)

// Register the component globally
Vue.component('font-awesome-icon', FontAwesomeIcon)

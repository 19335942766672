<template>
  <div class="vpo-nhanvien-leftsidebar">
    <el-tabs
      type="card"
      v-model="activeName"
      @tab-click="handleClick"
      class="d-lg-block d-none"
    >
      <el-tab-pane
        v-for="(tab, index) in activeGroup"
        :key="index"
        :name="tab.name"
        :disabled="!tab.show || tab.dissapear"
      >
        <div
          slot="label"
          @click="toPage({ path: tab.path })"
          class="inner"
          :class="{ 'text-primary': tab.name == activeName }"
        >
          <font-awesome-icon :icon="tab.icon" />
          <span class="align-middle">{{ $t(tab.text) }}</span>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- mobile -->
    <el-select
      size="mini"
      :value="activeNameDisp"
      placeholder=""
      class="d-block d-lg-none mt--2"
    >
      <el-option
        v-for="tab in activeGroup"
        :name="tab.name"
        :key="tab.text"
        :label="tab.text"
        :value="tab.text"
      >
        <div @click="toPage({ path: tab.path })">
          <span>{{ $t(tab.text) }}</span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { find, propEq, pluck, values, flatten } from 'ramda'
import Constant from '@/plugins/Constant'
import ApproveFlow from '@frontendCommon/dao/ApproveFlow'

import { mapState } from 'vuex'
export default {
  name: 'TabManager',
  components: {},
  props: {
    group: {
      type: String,
      default: () => 'workingTime',
    },
    active: {
      type: String,
      default: () => 'tab-1',
    },
  },
  data() {
    return {
      activeName: this.active,
      approveFlows: [],
      groups: Constant.TABS,
      activeGroup: false,
      clientSettings: this.$store.state.auth.clientWorkflowSetting,
    }
  },
  computed: {
    activeNameDisp() {
      const tab = this.groups[this.group].find(
        (v) => v.name === this.activeName
      )
      return tab ? this.$t(tab.text) : ''
    },
    ...mapState({
      user: (state) => state.auth.user,
      authPermissions: (state) => state.auth.permissions,
      client: (state) => state.auth.client,
    }),
    clientWorkflowSetting() {
      return this.client.clientWorkflowSetting
    },
  },
  async created() {
    if (this.clientWorkflowSetting.advanced_permission_flow) {
      this.activeGroup = this.getActiveGroup()
    } else {
      if (
        this.group == 'quan_ly_nhanvien' &&
        !this.authPermissions.includes('manage-employee')
      ) {
        this.$router.push({ path: '/ho-so-ca-nhan/thong-tin-co-ban' })
        return
      }

      if (
        this.group == 'quan_ly_tai_khoan' &&
        !this.authPermissions.includes('manage-employee')
      ) {
        this.$router.push({  path: '/' })
        return
      }

      /* Show hide tab using client setting */
      if (
        this.group == 'bang_luong_nhan_vien' &&
        this.active == 'bang_luong_nhan_vien' &&
        !this.clientWorkflowSetting.payslip_complaint
      ) {
        this.$router.push({ path: '/' })
      }
      this.activeGroup = this.getActiveGroup()
    }
    if (
      this.group == 'yeu_cau_thanh_toan' &&
      this.active == 'nha-cung-cap' &&
      !this.clientWorkflowSetting.enable_create_supplier_for_individual
    ) {
      this.$router.push({ path: '/' })
      return
    }

    this.activeGroup = this.getActiveGroup()
  },
  methods: {
    userCan(permission) {
      return this.authPermissions.includes(permission)
    },
    checkArraysMatch(array1, array2) {
      return array1.every((item) => array2.includes(item))
    },
    userInFlow(flowName) {
      let userFlows = flatten(
        pluck('approveFlowUsers', values(this.approveFlows[flowName]))
      )

      let isInFlow = false

      userFlows.forEach((f) => {
        isInFlow = isInFlow || f.user.id == this.user.id
      })

      return isInFlow
    },
    toPage(params) {
      this.$router.push(params)
    },
    handleClick(tab, event) {
      this.activeName = tab.name
    },
    loadApproveFlows() {
      return new Promise((resolve, reject) => {
        ApproveFlow.getAll(this.user.client_id)
          .then((flows) => {
            resolve(flows)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    isActive(groupName, path) {
      let group = this.groups[groupName]

      return find(propEq('path', path))(group)
    },
    getActiveGroup() {
      let group = this.groups[this.group]
      group.forEach((g, index, object) => {
        let route = find(propEq('name', g.name.replaceAll('-', '_')))(
          this.$router.options.routes
        )
        if (this.clientSettings.advanced_permission_flow) {
          if (route && route.advanced_permissions) {

            const neededPermissions = route.advanced_permissions
            const myPermissions = this.authPermissions
            if (neededPermissions.length) {
              let hasPermission = false
              // OR
              for (let i = 0; i < neededPermissions.length; i++) {
                const permission = neededPermissions[i]
                if (myPermissions.indexOf(permission) !== -1) {
                  hasPermission = true
                }
              }
              if (
                route.name === 'quan_ly_bang_luong_quan_ly' ||
                route.name === 'lich_su_luong'
              ) {
                hasPermission = this.checkArraysMatch(
                  neededPermissions,
                  myPermissions
                )
              }
              g.show = hasPermission
            } else {
              g.show = true
            }
          } else {

            g.show = [
              'timesheet',
              'profile',
              'bang_luong_nhan_vien',
              'danh_gia_ban_than',
            ].includes(this.group)
              ? true
              : false
          }
        } else {
          if (route && route.permissions) {
            const neededPermissions = route.permissions
            const myPermissions = this.authPermissions
            if (neededPermissions.length) {
              let hasPermission = false
              // OR
              for (let i = 0; i < neededPermissions.length; i++) {
                const permission = neededPermissions[i]
                if (myPermissions.indexOf(permission) !== -1) {
                  hasPermission = true
                }
              }

              g.show = hasPermission
            } else {
              g.show = true
            }
          } else {

            g.show = [
              'timesheet',
              'profile',
              'bang_luong_nhan_vien',
              'danh_gia_ban_than',
            ].includes(this.group)
              ? true
              : false
          }
        }

        if(this.clientWorkflowSetting.advanced_permission_flow){
          if(g.name == 'quan-ly-dang-ky-cong-so-leave') g.show = this.userCan('advanced-manage-timesheet-leave-read') && this.clientWorkflowSetting.enable_leave_request
          if(g.name == 'quan-ly-dang-ky-cong-so-ot') g.show = this.userCan('advanced-manage-timesheet-overtime-read') && this.clientWorkflowSetting.enable_overtime_request
          if(g.name == 'quan-ly-dang-ky-cong-so-cong-tac') g.show = this.userCan('advanced-manage-timesheet-outside-working-wfh-read') && this.clientWorkflowSetting.enable_bussiness_request
        } else {
          if(g.name == 'quan-ly-dang-ky-cong-so-leave') {
            if(this.clientWorkflowSetting.enable_leave_request){
              g.show = this.userCan('manage-timesheet') || this.userCan('CLIENT_REQUEST_OFF')
            } else {
              g.show = false
            }
          }
          if(g.name == 'quan-ly-dang-ky-cong-so-ot') {
            if(this.clientWorkflowSetting.enable_overtime_request){
              g.show = this.userCan('manage-timesheet') || this.userCan('CLIENT_REQUEST_OT') || this.userCan('CLIENT_REQUEST_OT_ASSIGNMENT')
            } else {
              g.show = false
            }
          }
          if(g.name == 'quan-ly-dang-ky-cong-so-cong-tac') {
            if(this.clientWorkflowSetting.enable_bussiness_request){
              g.show = this.userCan('manage-timesheet') || this.userCan('CLIENT_REQUEST_CONG_TAC')
            } else {
              g.show = false
            }
          }
          /* Individual */
          if(g.name == 'dang-ky-cong-so-nghi-phep') g.show = this.clientWorkflowSetting.enable_leave_request
          if(g.name == 'dang-ky-cong-so-ot') g.show = this.clientWorkflowSetting.enable_overtime_request
          if(g.name == 'dang-ky-cong-so-cong-tac') g.show = this.clientWorkflowSetting.enable_bussiness_request
        }

        if(g.name == 'quan-ly-tin-tuyen-dung') {
          if(this.clientWorkflowSetting.enable_recruit_function){
            if(this.userCan('manage-jobboard')){
              g.show = true;
            } else {
              g.show = false;
            }
          } else {
            g.show = false
          }
        }
        if(g.name === 'quan-ly-danh-gia' || g.name === 'mau_cau_hoi') {
          if(this.clientWorkflowSetting.enable_evaluate){
            if(this.userCan('manage-evaluation')){
              g.show = true;
            } else {
              g.show = false;
            }
          } else {
            g.show = false
          }
        }

        if (g.name == 'location-checkin')
          g.show =
            this.clientWorkflowSetting.enable_location_checkin &&
            this.userCan('manage-location-checkin')
        if (g.name == 'lao-dong-nuoc-ngoai')
          g.show = this.user.clientEmployee.nationality != 'Việt Nam'
        if (g.name == 'my_project')
          g.show = this.clientWorkflowSetting.enable_client_project
        if (g.name == 'khieu-nai-bang-luong')
          g.show = this.clientWorkflowSetting.payslip_complaint
        if (g.name == 'nha-cung-cap')
          g.show =
            this.clientWorkflowSetting.enable_create_supplier_for_individual
        if (
          g.name == 'danh-sach-don-nguoi-phu-thuoc' ||
          g.name == 'ho-so-nguoi-phu-thuoc'
        ) {
          g.show = this.client.client_type === 'outsourcing'
        } else if (g.name == 'quan-ly-tai-khoan') {
          g.show = this.client.client_type !== 'outsourcing' && this.user.role === 'manager'
        }
      })
      return group
    },
  },
}
</script>

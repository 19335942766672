<template>
  <!-- <div
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
    :data="backgroundColor"
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
  > -->
   <div
    :data="backgroundColor"
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white vpo-sidebar"
    @mouseover="mouseOver" @mouseleave="mouseLeave" :class="{
        'hovered': isHovered,
      }"
  >
    <div ref="sidebarScrollArea" class="scrollbar-inner position-relative vpo-scrollbar">
      <div class="d-none d-lg-block mb-2">
        <div class="sidenav-header d-flex align-items-center justify-content-center">
          <a class="navbar-brand flex-grow-1 cursor-pointer" @click="redirectPage">
            <img src="/img/logo.svg" class="navbar-brand-img" alt="Sidebar logo" />
            <img src="/img/logo-text.svg" class="navbar-brand-text" alt="Sidebar logo" />
          </a>
          <!-- Sidenav toggler -->
          <div
            :class="{'active': !$sidebar.isMinimized }"
            @click="minimizeSidebar"
            class="sidenav-toggler"
          >
            <svg data-v-e4dab074="" width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg"><rect data-v-e4dab074="" x="19.4358" y="6.85876" width="2" height="19.4358" rx="1.3342" transform="rotate(90 19.4358 6.85876)" fill="#B1B1B1"></rect> <rect data-v-e4dab074="" x="12.7656" y="13.5297" width="2" height="12.7655" rx="1.3342" transform="rotate(90 12.7656 13.5297)" fill="#B1B1B1"></rect> <rect data-v-e4dab074="" x="12.7656" y="0.187744" width="2" height="12.7656" rx="1.3342" transform="rotate(90 12.7656 0.187744)" fill="#B1B1B1"></rect> <path data-v-e4dab074="" d="M16.5277 3.65998C16.2348 3.36708 15.76 3.36708 15.4671 3.65998C15.1742 3.95287 15.1742 4.42774 15.4671 4.72064L16.5277 3.65998ZM20 8.19289L20.5303 8.72322L21.0606 8.19289L20.5303 7.66256L20 8.19289ZM15.4671 11.6651C15.1742 11.958 15.1742 12.4329 15.4671 12.7258C15.76 13.0187 16.2348 13.0187 16.5277 12.7258L15.4671 11.6651ZM15.4671 4.72064L19.4696 8.72322L20.5303 7.66256L16.5277 3.65998L15.4671 4.72064ZM19.4696 7.66256L15.4671 11.6651L16.5277 12.7258L20.5303 8.72322L19.4696 7.66256Z" fill="#B1B1B1"></path></svg>
          </div>
        </div>
      </div>
      <div class="d-lg-none d-block mt-3">
        <!--<div class="text-center pb-2"><img :src="logo" class="navbar-brand-img" alt="Sidebar logo"></div>-->
        <div class="text-center pb-2"><img src="/img/theme/logo_terra.png" width="160" class="navbar-brand-img"
                                           alt="Sidebar logo"></div>
        <div class="h2 nav-link-text px-4 text-center">{{ name }}</div>
      </div>
      <slot/>
      <div class="navbar-inner position-relative">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              />
            </sidebar-item>
          </slot>
        </ul>
        <slot name="links-after"/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Sidebar',
    props: {
      title: {
        type: String,
        default: 'Creative Tim',
        description: 'Sidebar title'
      },
      shortTitle: {
        type: String,
        default: 'CT',
        description: 'Sidebar short title'
      },
      logo: {
        type: String,
        default: '/img/theme/logo_terra_customer.png',
        description: 'Sidebar app logo'
      },
      backgroundColor: {
        type: String,
        default: 'vue',
        validator: (value) => {
          const acceptedValues = [
            '',
            'vue',
            'blue',
            'green',
            'orange',
            'red',
            'primary'
          ]
          return acceptedValues.includes(value)
        },
        description:
          'Sidebar background color (vue|blue|green|orange|red|primary)'
      },
      sidebarLinks: {
        type: Array,
        default: () => [],
        description:
          'List of sidebar links as an array if you don\'t want to use components for these.'
      },
      autoClose: {
        type: Boolean,
        default: true,
        description:
          'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    data() {
      const user = this.$store.getters['auth/user']
      return {
        isHovered: false,
        name: user.name,
        role: user.role
      }
    },
    provide() {
      return {
        // autoClose: this.autoClose
      }
    },
    mounted() {
      this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth
      this.minimizeSidebar()
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    },
    methods: {
      mouseOver() {
      this.isHovered = true
    },
    mouseLeave() {
      this.isHovered = false
    },
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize()
        }
      },
      redirectPage() {
        // check current route != dashboard must direct
        if (this.$route.name !== 'dashboard') {
          this.$router.push({ name: 'dashboard' })
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .navbar-brand {

    .navbar-brand-img {
      max-width: 100%;
      max-height: 100%;
      width: 38px;
    }

    .navbar-brand-text {
      max-width: 100%;
      max-height: 100%;
      width: 97px;
      margin-left: 7px;
    }
  }

  .vpo-sidebar.hovered{
    max-width: 243px;
  }
  .vpo-scrollbar{
    overflow-x: hidden;
    overflow-y: auto;
  }
  .vpo-scrollbar::-webkit-scrollbar {
    display: none;
  }
</style>

import { remove } from 'ramda'
import moment from 'moment'

import api from '@/util/api'
import { getErrorCodeFromResponse } from '@/../frontend-common/utils/helper'
import Auth from '@/graphql/auth.gql'
import Client from '@/graphql/client.gql'
import ClientEmployee from '@/graphql/client_employee.gql'
import Timesheet from '@/graphql/timesheet.gql'
import ClientCustomVariable from '@/graphql/client_custom_variable.gql'
import ClientEmployeeCustomVariable from '@/graphql/client_employee_custom_variable.gql'
import CalculationSheetTemplate from '@/graphql/calculation_sheet_template.gql'
import CalculationSheetClientEmployee from '@/graphql/calculation_sheet_client_employee.gql'
import CalculationSheetVariable from '@/graphql/calculation_sheet_variables.gql'
import CalculationSheetTemplateAssignment from '@/graphql/calculation_sheet_template_assignments.gql'
import CalculationSheet from '@/graphql/calculation_sheet.gql'
import CalculationSheetExportTemplate from '@/graphql/calculation_sheet_export_template.gql'
import PayrollAccountantExportTemplate from '@/graphql/payroll_accountant_export_template.gql'
import PayrollAccountantTemplate from '@/graphql/payroll_accountant_template.gql'
import User from '@/graphql/user.gql'
import ClientEmployeeEarlyLateRequest from '@/graphql/client_employee_early_late_request.gql'
import ClientEmployeeLeaveRequest from '@/graphql/client_employee_leave_request.gql'
import ClientEmployeeOvertimeRequest from '@/graphql/client_employee_overtime_request.gql'
import SupportTicket from '@/graphql/support_ticket.gql'
import SupportTicketComment from '@/graphql/support_ticket_comment.gql'
import WorkSchedule from '@/graphql/work_schedules.gql'
import WorkScheduleGroup from '@/graphql/work_schedule_groups.gql'
import WorkScheduleGroupTemplate from '@/graphql/work_schedule_group_templates.gql'
import Report from '@/graphql/report.gql'
import ClientWorkflowSetting from '@/graphql/client_workflow_setting.gql'
import Approve from '@/graphql/approve.gql'
import ClientAssignment from '@/graphql/client_assignment.gql'
import Notification from '@/graphql/notification.gql'
import DebitNote from '@/graphql/debit_note.gql'
import KnowledgeQuestion from '@/graphql/knowledge_questions.gql'
import SocialSecurityProfile from '@/graphql/social_security_profile.gql'
import Translation from '@/graphql/translation.gql'
import SocialSecurityClaim from '@/graphql/social_security_claim.gql'
import ClientEmployeeContract from '@/graphql/client_employee_contract.gql'
import ClientEmployeeForeignWorkpermit from '@/graphql/client_employee_foreign_workpermit.gql'
import ClientEmployeeForeignVisa from '@/graphql/client_employee_foreign_visa.gql'
import ClientWifiCheckinSpot from '@/graphql/client_wifi_checkin_spot.gql'
import ClientLocationCheckin from '@/graphql/client_location_checkin.gql'
import TrainingSeminar from '@/graphql/training_seminar.gql'
import TrainingTeacher from '@/graphql/training_teacher.gql'
import ClientAppliedDocument from '@/graphql/client_applied_document.gql'
import Comment from '@/graphql/comment.gql'
import Media from '@/graphql/media.gql'
import WorktimeRegister from '@/graphql/worktime_register.gql'
import ClientEmployeeTrainingSeminar from '@/graphql/client_employee_training_seminar.gql'
import ClientHeadcount from '@/graphql/client_headcount.gql'
import Contract from '@/graphql/contract.gql'
import ContractTemplate from '@/graphql/contract_template.gql'
import ClientDepartment from '@/graphql/client_department.gql'
import Setting from '@/graphql/setting.gql'
import JobboardJob from '@/graphql/jobboard_job.gql'
import JobboardSetting from '@/graphql/jobboard_setting.gql'
import Formula from '@/graphql/formula.gql'
import JobboardApplication from '@/graphql/jobboard_application.gql'
import Evaluation from '@/graphql/evaluation.gql'
import EvaluationUser from '@/graphql/evaluation_user.gql'
import SocialSecurityClaimTracking from '@/graphql/social_security_claim_tracking.gql'
import ClientCameraDevice from '@/graphql/client_camera_checkin_device.gql'
import HanetSetting from '@/graphql/hanet_setting.gql'
import HanetDevice from '@/graphql/hanet_device.gql'
import HanetPlace from '@/graphql/hanet_place.gql'
import HanetPerson from '@/graphql/hanet_person.gql'
import HanetLog from '@/graphql/hanet_log.gql'
import ClientEmployeeDependent from '@/graphql/client_employee_dependent.gql'
import ClientEmployeeLocationHistory from '@/graphql/client_employee_location_history.gql'
import ClientProject from '@/graphql/client_project.gql'
import ClientProjectEmployee from '@/graphql/client_project_employee.gql'
import ClientProjectTimelog from '@/graphql/client_project_timelog.gql'
import JobboardAssignment from '@/graphql/jobboard_assignment.gql'
import PaymentRequest from '@/graphql/payment_request.gql'
import PaidLeaveChange from '@/graphql/paid_leave_change.gql'
import AppliedDocumentCategory from '@/graphql/applied_document_category.gql'
import SocialSecurityProfileRequest from '@/graphql/social_security_profile_request.gql'
import WorktimeRegisterCategory from '@/graphql/worktime_register_category.gql'
import ClientEmployeeGroup from '@/graphql/client_employee_group.gql'
import ClientEmployeeGroupAssignment from '@/graphql/client_employee_group_assignment.gql'
import ActionLog from '@/graphql/action_log.gql'
import TimesheetShift from '@/graphql/timesheet_shift.gql'
import RecruitmentProcess from '@/graphql/recruitment_process.gql'
import RecruitmentProcessAssigment from '@/graphql/recruitment_assignment.gql'
import ClientPosition from '@/graphql/client_position.gql'
import PowerBiReport from '@/graphql/power_bi_report.gql'
import Bank from '@/graphql/bank.gql'
import Suppliers from '@/graphql/suppliers.gql'
import Beneficiary from '@/graphql/beneficiary.gql'
import LeaveCategory from '@/graphql/leave_category.gql'
import OvertimeCategory from '@/graphql/overtime_category.gql'
import PayslipComplant from '@/graphql/payslip_complaint.gql'
import ClientEmployeeLeaveManagement from '@/graphql/client_employee_leave_management.gql'
import PaymentRequestExportTemplate from '@/graphql/payment_request_export_template.gql'
import GroupApproval from '@/graphql/group_approval.gql'
import RecruitmentProcessTemplate from '@/graphql/recruitment_process_template.gql'
import EvaluationObject from '@/graphql/evaluation_object.gql'
import EvaluationParticipant from '@/graphql/evaluation_participant.gql'
import WebVersion from '@/graphql/web_version.gql'
import WebFeatureSlider from '@/graphql/web_feature_slider.gql'
import DebitSetup from '@/graphql/debit_setup.gql'
import TrainingTemplate from '@/graphql/training_template.gql'
import TrainingLocation from '@/graphql/training_location.gql'
import TrainingSeminarSchedule from '@/graphql/training_schedule.gql'
import TrainingSeminarAttendance from '@/graphql/training_attendance.gql'
import ImportablePITData from '@/graphql/importable_pit_data.gql'
import Posts from '@/graphql/posts.gql'
import TimesheetShiftTemplate from '@/graphql/timesheet_shift_template.gql'

const schemas = {
  Auth,
  User,
  Client,
  ClientEmployee,
  Timesheet,
  ClientCustomVariable,
  ClientEmployeeCustomVariable,
  CalculationSheetTemplate,
  CalculationSheetClientEmployee,
  CalculationSheetVariable,
  CalculationSheetTemplateAssignment,
  CalculationSheet,
  CalculationSheetExportTemplate,
  PayrollAccountantTemplate,
  PayrollAccountantExportTemplate,
  ClientEmployeeEarlyLateRequest,
  ClientEmployeeLeaveRequest,
  ClientEmployeeOvertimeRequest,
  SupportTicket,
  SupportTicketComment,
  WorkSchedule,
  Report,
  ClientWorkflowSetting,
  WorkScheduleGroup,
  WorkScheduleGroupTemplate,
  Approve,
  ClientAssignment,
  Notification,
  DebitNote,
  KnowledgeQuestion,
  SocialSecurityProfile,
  Translation,
  SocialSecurityClaim,
  ClientEmployeeContract,
  ClientEmployeeForeignWorkpermit,
  ClientEmployeeForeignVisa,
  ClientWifiCheckinSpot,
  ClientLocationCheckin,
  TrainingSeminar,
  TrainingTeacher,
  ClientAppliedDocument,
  Comment,
  Media,
  WorktimeRegister,
  ClientEmployeeTrainingSeminar,
  ClientHeadcount,
  Contract,
  ContractTemplate,
  ClientDepartment,
  Setting,
  JobboardJob,
  JobboardSetting,
  Formula,
  JobboardApplication,
  Evaluation,
  EvaluationUser,
  ClientCameraDevice,
  HanetSetting,
  HanetDevice,
  HanetPlace,
  HanetPerson,
  HanetLog,
  ClientEmployeeDependent,
  ClientEmployeeLocationHistory,
  ClientProject,
  ClientProjectEmployee,
  ClientProjectTimelog,
  JobboardAssignment,
  PaymentRequest,
  PaidLeaveChange,
  AppliedDocumentCategory,
  SocialSecurityClaimTracking,
  SocialSecurityProfileRequest,
  WorktimeRegisterCategory,
  ClientEmployeeGroup,
  ClientEmployeeGroupAssignment,
  ActionLog,
  TimesheetShift,
  RecruitmentProcess,
  RecruitmentProcessAssigment,
  ClientPosition,
  PowerBiReport,
  Bank,
  Suppliers,
  Beneficiary,
  LeaveCategory,
  OvertimeCategory,
  PayslipComplant,
  ClientEmployeeLeaveManagement,
  PaymentRequestExportTemplate,
  GroupApproval,
  RecruitmentProcessTemplate,
  EvaluationObject,
  EvaluationParticipant,
  WebVersion,
  WebFeatureSlider,
  DebitSetup,
  TrainingTemplate,
  TrainingLocation,
  TrainingSeminarSchedule,
  TrainingSeminarAttendance,
  ImportablePITData,
  Posts,
  TimesheetShiftTemplate,
}

const gqlQuery = (schema, action, variables, silent) => {
  return api.query(schemas[schema][action], variables, silent)
}
const gqlSingleQuery = (schema, action, variables, silent) => {
  return api.singleQuery(schemas[schema][action], variables, silent)
}

const singleQuery = (schema, action, variables, silent) => {
  return api.singleQuery(schemas[schema][action], variables, silent)
}

const gqlMutate = (schema, action, variables, silent) => {
  return api.mutate(schemas[schema][action], variables, silent)
}

const singleMutate = (schema, action, variables, silent) => {
  return api.singleMutate(schemas[schema][action], variables, silent)
}

const gqlUpload = (schema, action, variables) => {
  return api.upload(schemas[schema][action], variables)
}

const nl2br = (str) => {
  return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
}

const convertToSlug = (title) => {
  let slug = title.toLowerCase()

  // Đổi ký tự có dấu thành không dấu
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
  slug = slug.replace(/đ/gi, 'd')
  // Xóa các ký tự đặt biệt
  slug = slug.replace(/[`~!@#|$%^&*()+=,./?><'":;_]/gi, '')
  slug = slug.replace(/[,.\- ]/g, '_')
  // Đổi khoảng trắng thành ký tự gạch ngang
  slug = slug.replace(/ /gi, '_')
  // Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
  // Phòng trường hợp người nhập vào quá nhiều ký tự trắng
  slug = slug.replace(/-----/gi, '_')
  slug = slug.replace(/----/gi, '_')
  slug = slug.replace(/---/gi, '_')
  slug = slug.replace(/--/gi, '_')
  // Xóa các ký tự gạch ngang ở đầu và cuối
  slug = '@' + slug + '@'
  slug = slug.replace(/@-|-@|@/gi, '')

  return slug
}

const formatUsernameForDisplay = (username) => {
  const usernames = username.split('_')

  if (usernames.length > 0) {
    const newUsername = remove(0, 1, usernames)

    return newUsername.join('_')
  }

  return username
}

const getClientCodeFromUsername = (username) => {
  const usernames = username.split('_')

  if (usernames.length > 0) {
    return usernames[0]
  }

  return ''
}

const getDuration = (fromDate, toDate) => {
  const startTime = moment(fromDate)
  const endTime = moment(toDate)
  const duration = moment.duration(endTime.diff(startTime))
  return duration.asHours()
}

const formatDateTime = (datetime, format) => {
  format = format || 'DD/MM/YYYY HH:mm'

  return moment(datetime).format(format)
}

const getEmployeeOneLineName = (clientEmployee) =>
  '[' + clientEmployee.code + '] ' + clientEmployee.full_name

const toPrecision = function (value) {
  const v = value || 0

  return Math.round(v * 100) / 100
}

const formatMoney = function (value) {
  const v = Math.round(value)

  return new Intl.NumberFormat('vn-VI').format(v)
}

const getMoment = function (locale) {
  moment.suppressDeprecationWarnings = true
  moment.locale(locale)

  return moment
}

const downloadFileFromJson = (json) => {
  const rs = JSON.parse(json)
  const a = document.createElement('a')
  a.href = rs.file
  a.download = rs.name
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const generateUUID = () => {
  const S4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  const guid = `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`

  return guid.toLowerCase()
}

export const groupBy = (list, props) => {
  return list.reduce((a, b) => {
    ;(a[b[props]] = a[b[props]] || []).push(b)
    return a
  }, {})
}

export const validateMediaByByte = (first, second) => {
  const firstArray = Array.isArray(first) ? first : []
  const secondArray = Array.isArray(second) ? second : []
  const list = [...firstArray, ...secondArray]
  let size = 0
  for (let i = 0; i < list.length; i++) {
    if (list[i] && list[i].file_size) {
      size += list[i].file_size
    }
  }
  return size < 5200000
}

const groupPermissions = (user, authPermissions) => {
  let groupIds = user.groups.map((x) => x.id)
  let groupedData = {}
  let ungroupedData = []
  if (authPermissions && authPermissions.length > 0) {
    authPermissions.forEach((item) => {
      if (item.includes('advanced-manage')) {
        const nameParts = item.split('_')
        const groupName = nameParts.length > 1 ? nameParts.pop() : '0'
        item = nameParts.join('_')
        if (!groupedData[groupName]) {
          groupedData[groupName] = []
        }
        groupedData[groupName].push(item)
      } else {
        ungroupedData.push(item)
      }
    })
  }
  const mappedData = {}
  groupIds.forEach((key) => {
    if (groupedData[key]) {
      mappedData[key] = groupedData[key]
    }
  })

  const dataArray = Object.values(mappedData).flat()
  const combinedArray = Array.from(new Set(dataArray))
  const result = [...ungroupedData, ...combinedArray]
  return result
}

export default {
  gqlQuery,
  singleQuery,
  singleMutate,
  gqlMutate,
  gqlUpload,
  nl2br,
  convertToSlug,
  formatUsernameForDisplay,
  formatDateTime,
  formatMoney,
  getClientCodeFromUsername,
  getDuration,
  getEmployeeOneLineName,
  toPrecision,
  getMoment,
  downloadFileFromJson,
  generateUUID,
  groupBy,
  getErrorCodeFromResponse,
  validateMediaByByte,
  groupPermissions,
  gqlSingleQuery,
}
